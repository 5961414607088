import { Injectable } from '@angular/core';
import isToday from 'date-fns/isToday';
import isTomorrow from 'date-fns/isTomorrow';
import isYesterday from 'date-fns/isYesterday';
import { DatePipe, Time } from '@angular/common';
import { Adresse } from '../../../models/user.model';
import { isSet, orderBy } from 'lodash';

@Injectable({ providedIn: 'root' })
export class SharedService {
  constructor(private readonly datePipe: DatePipe) {}

  static dateToTime(date: Date | number): Time {
    return {
      hours: new Date(date).getHours(),
      minutes: new Date(date).getMinutes(),
    };
  }

  static groupBy<T, K>(array: T[], keyExtractor: (item: T) => K): Map<K, T[]> {
    return array.reduce((map, item) => {
      const key = keyExtractor(item);
      const group = map.get(key);
      if (!group) {
        map.set(key, [item]);
      } else {
        group.push(item);
      }

      return map;
    }, new Map<K, T[]>());
  }

  formatDateRdv(date: string | Date): string {
    const dataDate = new Date(date);
    let displayDate: string | null = '';
    if (dataDate) {
      if (isToday(dataDate)) {
        displayDate = "Aujourd'hui à ";
      } else if (isTomorrow(dataDate)) {
        displayDate = 'Demain à ';
      } else if (isYesterday(dataDate)) {
        displayDate = 'Hier à ';
      } else {
        displayDate = this.datePipe.transform(dataDate, 'shortDate') + ' - ';
      }
    }

    return displayDate ? displayDate + this.datePipe.transform(dataDate, 'shortTime') : 'Une erreur est survenue';
  }

  static areAdressesDifferent(a1?: Adresse, a2?: Adresse) {
    return (
      (!a1 && a2) ||
      (!a2 && a1) ||
      (a1 && a2 && (a1.codePostal !== a2.codePostal || a1.adresse !== a2.adresse || a1.ville !== a2.ville || a1.detail !== a2.detail))
    );
  }

  static isTimeBefore(time1: Time, time2: Time) {
    return time1.hours < time2.hours || (time1.hours === time2.hours && time1.minutes < time2.minutes);
  }

  static isTimeAfter(time1: Time, time2: Time) {
    return time1.hours > time2.hours || (time1.hours === time2.hours && time1.minutes > time2.minutes);
  }

  static orderByList(array: any, sortBy: string | string[] | ((i1: any, i2: any) => number), order?: 'asc' | 'desc'): any[] {
    const sortOrder: 'asc' | 'desc' = order ? order : 'asc'; // setting default ascending order
    if (isSet(array)) {
      array = Array.from(array);
    }

    if (typeof sortBy === 'function') {
      return array.sort(sortBy);
    }

    if (Array.isArray(sortBy)) {
      return orderBy(array, sortBy, [sortOrder]);
    }

    return orderBy(array, [sortBy], [sortOrder]);
  }
}
