<!-- <ion-header>
  <ion-toolbar class="ion-text-center">

  </ion-toolbar>
</ion-header> -->
<ion-content color="blue100">
  <ion-grid>
    <ion-row>
      <ion-col>
        <div class="img-toolbar ion-text-center">
          <img ngSrc="/assets/My_Milou-Logo.svg" class="logo" height="49" width="174" />
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-sm="10" offset-sm="1" size-xs="12" size-md="8" offset-md="2" size-lg="4" offset-lg="4" size-xl="4" offset-xl="4">
        <ion-card color="blue200">
          <div class="ion-text-center img-wrapper">
            <img src="/assets/illu2vetos.png" />
          </div>

          <ion-card-header class="ion-text-center">
            <ion-item lines="none" class="ion-text-center" color="blue200">
              <ion-label color="grey900" class="ion-text-wrap" id="connexion">
                <h1 class="login-text">
                  <ion-icon name="lock-closed" color="grey900" slot="start"></ion-icon>
                  <div class="connexion-flex">
                    <b>Connexion à </b>
                    <img ngSrc="/assets/My_Milou-Logo.svg" class="logo" height="23" width="83" />
                  </div>
                </h1>
              </ion-label>
            </ion-item>
          </ion-card-header>
          <ion-card-content>
            <form [formGroup]="loginForm" (ngSubmit)="submit()" (keyup.enter)="submit()">
              <ion-item lines="none" color="blue200">
                <!-- <ion-label position="floating"> E-mail</ion-label> -->
                <ion-input placeholder="Votre e-mail" type="email" formControlName="email" required autofocus></ion-input>
                <ion-note slot="error" *ngIf="loginForm.get('email')?.hasError('required') && loginForm.touched"> Email est <strong>requis</strong></ion-note>
              </ion-item>
              <ion-item id="input-pw" lines="none" color="blue200">
                <!-- <ion-label position="floating">Mot de passe</ion-label> -->
                <ion-input placeholder="Mot de passe" type="password" formControlName="password" required #passwordShow></ion-input>
                <ion-button fill="clear" color="white" shape="round" expand="block" slot="end" (click)="toggleShow()">
                  <ion-icon name="eye" color="blue600" *ngIf="showPassword"></ion-icon>
                  <ion-icon name="eye-off" color="blue600" *ngIf="!showPassword"></ion-icon>
                </ion-button>
                <ion-note slot="error" *ngIf="loginForm.get('password')?.hasError('required') && loginForm.touched">
                  Mot de passe est <strong>requis</strong>
                </ion-note>
              </ion-item>
              <div *ngIf="loginError$ | async as loginError" class="ion-text-center">
                <br />
                <ion-text color="danger">
                  <p>{{ loginError }}</p>
                </ion-text>
              </div>
              <br />
              <ion-button expand="block" type="submit" [disabled]="(loginLoading$ | async) || loginForm.invalid" color="grey900">
                <ion-label *ngIf="(loginLoading$ | async) === false"> Valider</ion-label>
                <ion-label *ngIf="loginLoading$ | async">
                  <ion-spinner name="crescent"></ion-spinner>
                </ion-label>
              </ion-button>
            </form>

            <br />
            <a [routerLink]="'/forgot-password'">Mot de passe oublié</a>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
