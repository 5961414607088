<div class="ordonnance-wrapper">
  <div class="col-formulaire">
    <div class="welcome">
      <ion-text color="grey900">
        <h2>Prescription</h2>
        <p>Ordonnance(s) pour {{ animal?.nom }}</p>
      </ion-text>
    </div>
    <form [formGroup]="form" *ngIf="ordoEditable">
      <div formArrayName="ordonnances">
        <ng-container *ngFor="let ordo of ordonnances.controls; let idxOrdo = index; let last = last">
          <ion-card class="card-ordo" *ngIf="ordo.enabled; else ordoEnvoyee">
            <ion-card-header>
              <ion-grid>
                <ion-row>
                  <ion-col class="delete">
                    <ion-text color="blue600" class="ion-text-center">
                      <h2>{{ 'Ordonnance n°' + (idxOrdo + 1) }}</h2>
                    </ion-text>

                    <ion-button color="suppr" size="small" (click)="deleteOrdonnance(idxOrdo)" *ngIf="!listIdOrdonnancesPayees.includes(ordo.get('id')!.value)">
                      <ion-icon name="close-outline" slot="icon-only" color="wait" size="small"></ion-icon>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card-header>

            <div class="card-content">
              <div [formGroupName]="idxOrdo">
                <div formArrayName="prescription">
                  <ion-grid>
                    <ng-container *ngFor="let p of prescription(idxOrdo).controls; let i = index">
                      <div [formGroupName]="i" class="prescription-line">
                        <ion-row class="produit">
                          <ng-container *ngIf="!mobile">
                            <ion-col size="10">
                              <fieldset>
                                <legend class="produit-legend">Produit n°{{ i + 1 }}</legend>
                                <ion-input
                                  placeholder="Chercher un produit"
                                  formControlName="produitSearch"
                                  [clearInput]="true"
                                  (ionInput)="searchProduit($event, idxOrdo, i)"
                                  type="text"
                                ></ion-input>
                              </fieldset>

                              <ion-list
                                class="ion-padding"
                                [hidden]="
                                  currentOrdoIdxForSearch !== idxOrdo || currentProduitIdxForSearch !== i || produits.length <= 0 || !!p.get('produit')!.value
                                "
                              >
                                <ion-item *ngFor="let produit of produits" (click)="selectProduit(produit, idxOrdo, i)" [style.cursor]="'pointer'">
                                  <ion-label class="ion-text-wrap">
                                    <h3>{{ produit.nom }}</h3>
                                    <p>{{ produit.packaging }}</p>
                                  </ion-label>
                                  <!-- <span>{{ produit.nom + '(' + produit.packaging + ')' }}</span> -->
                                </ion-item>
                              </ion-list>

                              <ion-select hidden formControlName="produit"></ion-select>
                            </ion-col>
                            <ion-col size="1.5" offset="0.5" class="quantity">
                              <fieldset>
                                <legend class="ion-text-wrap" [class]="p.get('produit')!.value?.unite ? 'with-unit' : ''">
                                  Qté {{ p.get('produit')!.value?.unite ? ' (' + (p.get('produit')!.value.unite | lowercase) + ')' : '' }}
                                </legend>
                                <french-number-input formControlName="quantity"></french-number-input>
                              </fieldset>
                            </ion-col>
                            <ion-col
                              size="12"
                              class="ion-text-center"
                              *ngIf="p.enabled && p.get('produit')!.value?.unite && p.get('produit')!.value?.uvc > p.get('produit')!.value?.uva"
                            >
                              <ion-label class="text-more-visible">Attention, ce produit se facture par {{ p.get('produit')!.value?.unite }} </ion-label>
                            </ion-col>
                          </ng-container>
                          <ng-container *ngIf="mobile">
                            <ion-col size="12">
                              <fieldset>
                                <legend class="produit-legend">Produit n°{{ i + 1 }}</legend>
                                <ion-input
                                  placeholder="Chercher un produit"
                                  formControlName="produitSearch"
                                  [clearInput]="true"
                                  (ionInput)="searchProduit($event, idxOrdo, i)"
                                  type="text"
                                ></ion-input>
                              </fieldset>

                              <ion-list
                                class="ion-padding"
                                [hidden]="
                                  currentOrdoIdxForSearch !== idxOrdo || currentProduitIdxForSearch !== i || produits.length <= 0 || !!p.get('produit')!.value
                                "
                              >
                                <ion-item *ngFor="let produit of produits" (click)="selectProduit(produit, idxOrdo, i)" [style.cursor]="'pointer'">
                                  <ion-label class="ion-text-wrap">
                                    <h3>{{ produit.nom }}</h3>
                                    <p>{{ produit.packaging }}</p>
                                  </ion-label>
                                  <!-- <span>{{ produit.nom + '(' + produit.packaging + ')' }}</span> -->
                                </ion-item>
                              </ion-list>

                              <ion-select hidden formControlName="produit"></ion-select>
                            </ion-col>
                            <ion-col size="12" class="quantity">
                              <fieldset>
                                <legend class="ion-text-wrap" [class]="p.get('produit')!.value?.unite ? 'with-unit' : ''">
                                  Quantité {{ p.get('produit')!.value?.unite ? ' (' + (p.get('produit')!.value.unite | lowercase) + ')' : '' }}
                                </legend>
                                <french-number-input formControlName="quantity"></french-number-input>
                              </fieldset>
                            </ion-col>
                            <ion-col
                              size="12"
                              class="ion-text-center"
                              *ngIf="p.get('produit')!.value?.unite && p.get('produit')!.value?.uvc > p.get('produit')!.value?.uva"
                            >
                              <ion-label class="text-more-visible">Attention, ce produit se facture par {{ p.get('produit')!.value?.unite }} </ion-label>
                            </ion-col>
                          </ng-container>
                          <ion-col size="12" class="posologie">
                            <ion-item lines="none">
                              <fieldset>
                                <legend>Posologie</legend>
                                <ion-textarea formControlName="posologie" placeholder="Saississez votre prescription"></ion-textarea>
                              </fieldset>
                            </ion-item>
                          </ion-col>

                          <ion-col size="7.5" *ngIf="displayDelivre">
                            <p></p>
                            <ion-item lines="none" color="blue200" class="delivered-item">
                              <ion-label
                                class="ion-text-wrap"
                                [style.font-weight]="prescription(idxOrdo).at(i).get('isDelivered')?.value === true ? 'bold' : 'normal'"
                              >
                                {{ prescription(idxOrdo).at(i).get('isDelivered')?.value === true ? 'Délivré' : 'Non délivré' }}
                              </ion-label>

                              <ion-checkbox formControlName="isDelivered" slot="start"></ion-checkbox>
                            </ion-item>
                          </ion-col>
                          <ion-col
                            size="4"
                            offset="0.5"
                            class="lot"
                            [hidden]="prescription(idxOrdo).at(i).get('isDelivered')?.value === false"
                            *ngIf="displayDelivre"
                          >
                            <ion-item lines="none" class="numLot-item">
                              <fieldset>
                                <legend>n° de lot</legend>
                                <ion-input formControlName="numLot" type="text"></ion-input>
                              </fieldset>
                            </ion-item>
                          </ion-col>
                          <ng-container *ngIf="!mobile">
                            <ion-col size="9">
                              <ion-item lines="none" class="renew">
                                <ion-label class="ion-text-wrap" color="grey900">Renouvellement autorisé :</ion-label>
                                <ion-input
                                  class="renouvelableFois"
                                  type="number"
                                  name="renouvelableFois"
                                  formControlName="renouvelableFois"
                                  min="0"
                                  max="10"
                                ></ion-input>
                                <ion-label class="ion-text-wrap fois">fois</ion-label>
                              </ion-item>
                            </ion-col>
                            <ion-col size="3" *ngIf="i > -1 && !listIdOrdonnancesPayees.includes(ordo.get('id')!.value)">
                              <div class="mobile-delete-btn">
                                <ion-button fill="clear" color="danger" (click)="supprimerProduit(idxOrdo, i)" size="small">
                                  <ion-icon name="close-outline" slot="start" color="danger" size="small"></ion-icon>
                                  <ion-label color="danger">Supprimer</ion-label>
                                </ion-button>
                              </div>
                            </ion-col>
                          </ng-container>
                          <ng-container *ngIf="mobile">
                            <ion-row>
                              <ion-col size="12">
                                <ion-item lines="none" class="renew">
                                  <ion-label class="ion-text-wrap" color="grey900" slot="start">Renouvellement autorisé : </ion-label>
                                  <ion-input type="number" name="renouvelableFois" formControlName="renouvelableFois" min="0" max="10"></ion-input>
                                  <ion-label class="ion-text-wrap">fois</ion-label>
                                </ion-item>
                              </ion-col>
                              <ion-col size="12" *ngIf="i > -1 && !listIdOrdonnancesPayees.includes(ordo.get('id')!.value)">
                                <div class="mobile-delete-btn">
                                  <ion-button fill="clear" color="danger" (click)="supprimerProduit(idxOrdo, i)" size="small">
                                    <ion-icon name="close-outline" slot="start" color="danger" size="small"></ion-icon>
                                    <ion-label color="danger">Supprimer</ion-label>
                                  </ion-button>
                                </div>
                              </ion-col>
                            </ion-row>
                          </ng-container>
                        </ion-row>
                      </div>
                    </ng-container>
                    <div>
                      <ion-button
                        expand="block"
                        (click)="addPrescription(idxOrdo)"
                        color="blue600"
                        class="add-btn"
                        fill="clear"
                        slot="start"
                        *ngIf="!listIdOrdonnancesPayees.includes(ordo.get('id')!.value)"
                      >
                        <ion-icon slot="start" name="add-circle-outline"></ion-icon>
                        <span> <ion-label slot="start">Ajouter un produit</ion-label></span>
                      </ion-button>
                    </div>
                  </ion-grid>
                  <br />

                  <!-- <div class="no-item ion-text-center">
                                                                              <ion-text color="grey400">
                                                                                <p>Aucune prescription</p>
                                                                              </ion-text>
                                                                            </div> -->
                </div>

                <div class="prescription-end">
                  <ion-row>
                    <ion-col size="12" class="infosSupplementaires">
                      <ion-item lines="none">
                        <fieldset>
                          <legend>Informations complémentaires de fin d'ordonnance</legend>
                          <ion-textarea
                            formControlName="infosSupplementaires"
                            name="infosSupplementaires"
                            placeholder="Saisissez des informations complémentaires à afficher en fin d'ordonnance"
                          ></ion-textarea>
                        </fieldset>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </div>
              </div>
            </div>

            <ion-row *ngIf="displaySaveBtns">
              <ion-col size="12">
                <div class="save-div">
                  <ion-button
                    color="secondary"
                    class="secondary-btn"
                    expand="block"
                    [disabled]="ordo.invalid || ordo.get('etatOrdonnance')!.value !== 'EN_COURS' || listIdOrdonnancesPayees.includes(ordo.get('id')!.value)"
                    (click)="saveOrdonnance(idxOrdo)"
                  >
                    <ion-spinner class="save-spinner" name="crescent" *ngIf="savingOrdoIdx !== undefined"></ion-spinner>
                    <ion-label>Enregistrer</ion-label>
                  </ion-button>
                  <ion-button
                    color="secondary"
                    class="big-button envoi-btn"
                    expand="block"
                    [disabled]="ordo.invalid || ordo.get('etatOrdonnance')!.value !== 'TERMINEE'"
                    (click)="finaliserOrdo(idxOrdo)"
                  >
                    <ion-label>Finaliser</ion-label>
                  </ion-button>
                </div>
                <br />
              </ion-col>
            </ion-row>
          </ion-card>
          <ng-template #ordoEnvoyee>
            <ion-card class="card-ordo">
              <ion-card-header>
                <ion-card-title class="ion-text-center" color="blue600">
                  Ordonnance n°{{ idxOrdo + 1 }} {{ ordo.get('numOrdonnance')!.value ? ' - ' + ordo.get('numOrdonnance')!.value : '' }}
                </ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <ion-item lines="none" class="ordo-payee-item" *ngFor="let produit of prescription(idxOrdo).controls; let i = index">
                  <ion-note slot="end">
                    <ion-text>{{ produit.get('numLot')!.value ? 'Lot n° ' + produit.get('numLot')!.value : '' }}</ion-text>
                  </ion-note>
                  <div class="ion-text-wrap">
                    <ion-text>
                      <b>{{ i + 1 }} / {{ produit.get('produit')!.value ? produit.get('produit')!.value.nom : produit.get('produitSearch')!.value }}</b>
                      (x{{ produit.get('quantity')!.value }})
                    </ion-text>
                    <br />
                    <ion-text>{{ produit.get('posologie')!.value }}</ion-text>
                    <br />
                    <ion-chip color="primary">
                      <ion-label color="grey900">
                        {{ produit.get('isDelivered')!.value ? 'Délivré' : 'A délivrer' }}
                      </ion-label>
                    </ion-chip>
                    <ion-chip color="primary">
                      <ion-label color="grey900" class="ion-text-wrap">
                        {{
                          !produit.get('renouvelableFois')!.value
                            ? 'Renouvellement interdit'
                            : 'Renouvellement autorisé ' + produit.get('renouvelableFois')!.value + ' fois'
                        }}
                      </ion-label>
                    </ion-chip>
                  </div>
                </ion-item>
                <ion-row>
                  <ion-col size="12">
                    <div class="save-div">
                      <ion-button color="secondary" class="big-button envoi-btn" expand="block" (click)="finaliserOrdo(idxOrdo)">
                        <ion-label>Envoyer</ion-label>
                      </ion-button>
                    </div>
                    <br />
                  </ion-col>
                </ion-row>
              </ion-card-content>
            </ion-card>
          </ng-template>
        </ng-container>

        <ion-text class="ion-text-center" *ngIf="!ordonnances.length" color="grey600">
          <p>Aucune ordonnance</p>
        </ion-text>
      </div>
      <div class="add-ordo">
        <ion-button expand="block" (click)="addOrdonnance()" color="blue600" class="add-btn" fill="clear" slot="start">
          <ion-icon slot="start" name="add-circle-outline"></ion-icon>
          <span> <ion-label slot="start">Ajouter une ordonnance</ion-label></span>
        </ion-button>
      </div>
    </form>
  </div>
  <div *ngIf="!ordoEditable" class="ordo-cloture">
    <ion-card *ngFor="let ordo of ordonnances.controls; let i = index">
      <ion-card-header>
        <ion-card-title>
          <div class="ion-text-center">
            <ion-text color="blue600">
              <h1>Ordonnance n°{{ i + 1 }} - {{ ordo.value.numOrdonnance }}</h1>
            </ion-text>
          </div>
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <div *ngFor="let produit of ordo.value.produitPrescrits; let i = index">
          <ion-item lines="none">
            <ion-note slot="helper">
              <p>{{ produit.delivre ? 'Délivré' : 'A délivrer' }}</p>
              <p>{{ produit.numLot ? 'Lot n° ' + produit.numLot : '' }}</p>
            </ion-note>
            <ion-label class="ion-text-wrap" color="grey900">
              <h2>
                <b>{{ i + 1 }}/ {{ produit.produit?.nom ? produit.produit!.nom : produit.nom }}</b>
                (x{{ produit.quantiteUvp }})
              </h2>
              <br />
              <p>{{ produit.posologie }}</p>
              <ion-chip color="primary">
                <ion-label color="grey900">
                  {{ !produit.renouvelableFois ? 'Renouvellement interdit' : 'Renouvellement autorisé ' + produit.renouvelableFois + ' fois' }}
                </ion-label>
              </ion-chip>
            </ion-label>
          </ion-item>
        </div>

        <div class="envoi-div">
          <ion-button color="secondary" class="big-button envoi-btn" expand="block" (click)="envoyerOrdonnanceClotureeParMail(ordo.value)">
            <ion-label>Envoyer</ion-label>
          </ion-button>
        </div>
        <ion-text class="ion-text-center" *ngIf="!ordonnances.controls?.length" color="grey600">
          <p>Aucune ordonnance</p>
        </ion-text>
      </ion-card-content>
    </ion-card>
    <ion-text class="ion-text-center" color="grey600" *ngIf="!ordonnances.controls?.length">
      <p>Aucune ordonnance</p>
    </ion-text>
  </div>
</div>
