<cdk-virtual-scroll-viewport
  (scrolledIndexChange)="onScrolledIndexChange($event)"
  [itemSize]="mobile ? itemSizePxMobile : itemSizePx"
  minBufferPx="1000"
  maxBufferPx="5000"
  [style.height]="'100%'"
  #viewport
>
  <ng-container *cdkVirtualFor="let item of items; trackBy: trackByFn; templateCacheSize: 0">
    <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
  </ng-container>
</cdk-virtual-scroll-viewport>
