import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAdmin from '../reducers/admin.reducer';
import { Race } from '../../../../models/animal.model';

export const selectAdminState = createFeatureSelector<fromAdmin.AdminState>('admin');

export const selectStructuresList = createSelector(selectAdminState, (state: fromAdmin.AdminState) => state.structureList);
export const selectEspeceList = createSelector(selectAdminState, (state: fromAdmin.AdminState) => state.especeList);
export const selectRaces = createSelector(selectAdminState, (state: fromAdmin.AdminState) => state.raceList);
export const selectRaceByEspece = (espece: string) => createSelector(selectRaces, (races: { [espece: string]: Race[] } | undefined) => races?.[espece]);
export const selectActiveStructure = createSelector(selectAdminState, (state: fromAdmin.AdminState) => state.activeStructure);
export const selectCommunesList = createSelector(selectAdminState, (state: fromAdmin.AdminState) => state.communeList);
export const selectLoadingProduits = createSelector(selectAdminState, (state: fromAdmin.AdminState) => state.isLoadingProduits);
export const selectVetoStructure = createSelector(selectAdminState, (state: fromAdmin.AdminState) => state.listVeto);
export const selectAsvList = createSelector(selectAdminState, (state: fromAdmin.AdminState) => state.listAsv);
export const selectActiveVeto = createSelector(selectAdminState, (state: fromAdmin.AdminState) => state.selectedVeto);
export const selectActiveAsv = createSelector(selectAdminState, (state: fromAdmin.AdminState) => state.selectedAsv);
