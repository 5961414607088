<ion-header class="ion-no-border ion-no-shadow">
  <ion-toolbar color="blue200" class="toolbar">
    <ion-title class="welcome stats ion-text-center">
      <h2>Ajout de compléments</h2>
    </ion-title>
    <ion-button shape="round" slot="start" fill="clear" (click)="cancel()" color="danger">
      <ion-icon slot="start" name="close-outline"></ion-icon>
      <ion-label>Fermer</ion-label>
    </ion-button>
    <div slot="end" class="save-btn">
      <ion-item color="bluee200" lines="none" shape="round" class="ion-justify-content-start">
        <ion-chip class="active-toggle">
          <ion-label class="ion-text-wrap" color="grey900">Notifier le client&nbsp;</ion-label>
          <ion-checkbox [(ngModel)]="this.notifyClient" mode="md"></ion-checkbox>
        </ion-chip>
      </ion-item>
      <ion-button shape="round" [disabled]="isSaving || (!filesToUpload.length && (!texte || texte === '<p></p>')) || !titre" (click)="save()" color="done">
        <!-- <ion-icon name="add-circle-outline" slot="start"></ion-icon> -->
        <ion-spinner name="crescent" *ngIf="isSaving" slot="start"></ion-spinner>

        <ion-icon slot="start" name="save-outline"></ion-icon>

        <ion-label>Enregistrer</ion-label>
      </ion-button>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size-xl="8" size-lg="8" size-md="12" size-sm="12" size-xs="12">
        <ion-item lines="none">
          <ion-input [(ngModel)]="titre" placeholder="Saisissez un titre"></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size-xl="4" size-lg="4" size-md="12" size-sm="12" size-xs="12">
        <ion-item lines="none">
          <ion-input
            [ngModel]="dateComplement | date: 'yyyy-MM-dd'"
            (ngModelChange)="setDateComplement($event)"
            [max]="now | date: 'yyyy-MM-dd'"
            type="date"
            name="dateDoc"
            placeholder="Date du doc"
          ></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div class="cr-editor">
    <ngx-editor-menu [editor]="editor" [toolbar]="editorToolbar"></ngx-editor-menu>
    <ngx-editor
      [(ngModel)]="texte"
      [outputFormat]="'html'"
      class="compte-rendu-editor"
      [editor]="editor"
      [disabled]="false"
      [placeholder]="'Saisissez votre anamnèse'"
    ></ngx-editor>
  </div>
  <upload-animal-file [user]="user" (files)="uploadFiles($event)"></upload-animal-file>
  <ion-row *ngIf="filesToUpload.length > 0">
    <ion-col>
      <ion-list class="file-list">
        <ion-item *ngFor="let file of filesToUpload; let i = index">
          <ng-container [ngSwitch]="file.type">
            <ion-icon slot="start" name="camera-outline" *ngSwitchCase="FileCRTypeEnum.PHOTO" title="Photo"></ion-icon>
            <ion-icon slot="start" name="chatbox-ellipses-outline" *ngSwitchCase="FileCRTypeEnum.RETOUR_REFERE" title="Retour référé"></ion-icon>
            <ion-icon slot="start" name="document-text-outline" *ngSwitchCase="FileCRTypeEnum.CERTIFICAT" title="Certificat"></ion-icon>
            <ion-icon slot="start" name="flask-outline" *ngSwitchCase="FileCRTypeEnum.RESULTAT_ANALYSE" title="Résultat d'analyse"></ion-icon>
            <ion-icon slot="start" name="document-outline" *ngSwitchCase="FileCRTypeEnum.AUTRE" title="Autre"></ion-icon>
          </ng-container>
          <ion-label class="ion-text-wrap">{{ (file.dateDocument | date: 'dd/MM/yyyy') + ' - ' + file.fileName }} </ion-label>
          <ion-button (click)="viewFile(file)" fill="outline" color="blue400" *ngIf="file.mimeType === 'application/pdf'">
            <ion-icon name="eye-outline" color="blue600"></ion-icon>
          </ion-button>
          <ion-button (click)="downloadFile(file)" fill="outline" color="blue400" *ngIf="file.mimeType !== 'application/pdf'">
            <ion-icon name="download-outline" color="blue600"></ion-icon>
          </ion-button>
          <ion-button (click)="deleteFile(i)" fill="outline" color="suppr">
            <ion-icon name="close-outline" color="wait"></ion-icon>
          </ion-button>
        </ion-item>
      </ion-list>
    </ion-col>
  </ion-row>
</ion-content>
