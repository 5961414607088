import { Pipe, PipeTransform } from '@angular/core';
import { ModePaiementEnum } from '../../../../models/paiement.model';

@Pipe({
  name: 'typePaiementStr',
  pure: true,
})
export class TypePaiementPipe implements PipeTransform {
  constructor() {}

  transform(value: ModePaiementEnum | string): string {
    switch (value) {
      case ModePaiementEnum.ALMA:
        return 'Alma';
      case ModePaiementEnum.CHEQUE:
        return 'chèque';
      case ModePaiementEnum.CARD:
        return 'carte bancaire';
      case ModePaiementEnum.ESPECE:
        return 'espèce';
      case ModePaiementEnum.VIREMENT:
        return 'virement';
      case ModePaiementEnum.DR_MILOU:
        return 'paiement en ligne';
      default:
        return '-';
    }
  }
}
