import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Pipe({
  name: 'orderBy',
  pure: false,
})
export class OrderByPipe implements PipeTransform {
  transform(array: any, sortBy: string | string[] | ((i1: any, i2: any) => number), order?: 'asc' | 'desc'): any[] {
    return SharedService.orderByList(array, sortBy, order);
  }
}
