import { UserRole } from './user.model';

export interface Interaction {
  id?: string;
  idRdv?: string;
  typeRdv?: string;
  dateEnvoi: Date;
  type: TypeInteraction;
  canal: TypeCanal;
  initiateur: { id: string; nom?: string; prenom?: string; role?: UserRole };
  destinataire?: { id: string; nom?: string; prenom?: string; role?: UserRole };
  contenu: string;
  priorite: number;
  conversation?: Conversation;
}

export interface Conversation {
  id: string;
  userAttribue: { id: string; nom: string; prenom: string; role: UserRole };
  initiateur: { id: string; nom: string; prenom: string; role: UserRole };
  destinataire: { id: string; nom: string; prenom: string; role: UserRole };
  dateModification: Date;
  dateCreation: Date;
  dateAttribution: Date;
  dateDernierMessage: Date;
  dateLectureInitiateur?: Date;
  dateLectureDestinataire?: Date;
  traitee: boolean;
}

export enum TypeInteraction {
  MODIFICATION_RDV = 'MODIFICATION_RDV',
  DEMANDE_RDV = 'DEMANDE_RDV',
  DEMANDE_REPLANIFICATION = 'DEMANDE_REPLANIFICATION',
  DEMANDE_PAIEMENT = 'DEMANDE_PAIEMENT',
  DEMANDE_ANNULATION = 'DEMANDE_ANNULATION',
  CALL_CLIENT = 'CALL_CLIENT',
  NOTIFICATION_CLIENT = 'NOTIFICATION_CLIENT',
  NOTIFICATION_VETO = 'NOTIFICATION_VETO',
  MESSAGE_INTERNE = 'MESSAGE_INTERNE',
  PAIEMENT_REUSSI = 'PAIEMENT_REUSSI',
}

export enum TypeRdv {
  DOMICILE = 'DOMICILE',
  CONSEIL = 'CONSEIL',
  TELECONSULTATION = 'TELECONSULTATION',
  REGULATION = 'REGULATION',
  INTERNE = 'INTERNE',
  HORS_RDV = 'HORS_RDV',
}

export enum TypeHorsRdv {
  ENVOI_MEDICAMENTS = 'ENVOI_MEDICAMENTS',
  ORDONNANCE = 'ORDONNANCE',
  AUTRE = 'AUTRE',
}

export enum TypeCanal {
  WEB = 'WEB',
  VETOPHONIE = 'VETOPHONIE',
  APP = 'APP',
  SMS = 'SMS',
  MAIL = 'MAIL',
  SUMUP = 'SUMUP',
  MESSAGERIE = 'MESSAGERIE',
  DIRECT = 'DIRECT',
  SYSTEME = 'SYSTEME',
}

export interface SearchInteractionRequest {
  includesTypes: TypeInteraction[];
  idInitiateur?: string;
  idDestinataire?: string;
  idInitiateurOrDestinataire?: string;
  idRdv?: string;
  dateMin?: Date;
  dateMax?: Date;
  idConversation?: string;
  excludeRdv?: boolean;
}

export interface SearchConversationRequest {
  idStructures?: string[];
  nonLues?: boolean;
  notIncludeAttribueesVeto?: boolean;
  page?: number;
  size?: number;
  offset?: number;
  idUserAttribue?: string;
  exludeOnlySystem?: boolean;
  nomClient?: string;
  nonTraitees?: boolean;
}
