<ion-fab class="chat" slot="fixed" horizontal="end" vertical="bottom">
  <!--<ion-badge *ngIf="unreadMessages.length > 0" class="text-badge-wait">
    <div>{{ unreadMessages.length }}</div>
  </ion-badge>-->
  <ion-fab-button color="secondary" (click)="showChat = !this.showChat">
    <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
  </ion-fab-button>
  <div class="chat-div" *ngIf="showChat">
    <div class="message-preview" #messagePreview>
      <div class="loading" *ngIf="isLoading">
        <div class="skapiroo-loading">
          <img src="assets/Skapiroo-56_56px.svg" />
        </div>
        <ion-spinner name="dots"></ion-spinner>
      </div>
      <div class="msg-wrapper" *ngFor="let message of chatMessages; let i = index; let first = first; let last = last">
        <div [class]="!message.fromUser ? 'left' : 'right'">
          <ion-card class="message">
            <ion-item>
              <div class="skapiroo" *ngIf="!message.fromUser">
                <img src="assets/Skapiroo-40_40px.svg" />
              </div>
              <ion-avatar *ngIf="message.fromUser">
                <img src="assets/illuveto.png" />
              </ion-avatar>
              <ion-label>
                <h2>
                  <b>{{ message.fromUser ? 'Vous' : 'Skapiroo' }}</b>
                </h2>
              </ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-label class="ion-text-wrap">
                <p [innerHTML]="message.message | markdown"></p>
              </ion-label>
            </ion-item>
          </ion-card>
        </div>
      </div>
    </div>
    <div class="message-response">
      <ion-toolbar color="blue200">
        <form [formGroup]="messageForm" (keyup.enter)="submit(); $event.preventDefault()">
          <ion-grid>
            <ion-row class="ion-align-items-center ion-justify-content-center">
              <ion-col size="10" class="ion-text-center">
                <ion-item color="white" lines="none" class="message-input">
                  <ion-input
                    inputmode="text"
                    placeholder="Répondre..."
                    formControlName="message"
                    minlength="1"
                    maxlength="2024"
                    required="true"
                    #messageInput
                  ></ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="2" class="ion-text-center">
                <ion-button
                  id="submitButton"
                  type="submit"
                  color="grey900"
                  fill="clear"
                  [disabled]="messageForm.invalid"
                  (mousedown)="submit(); $event.preventDefault()"
                >
                  <ion-icon name="send-outline" slot="icon-only" color="hiblue"></ion-icon>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </form>
      </ion-toolbar>
    </div>
  </div>
</ion-fab>
