import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Commune, Veterinaire } from 'app/models/admin.model';
import { Produit } from 'app/models/produit.model';
import { Acte, StructureVeterinaire } from '../../../../models/rdv.model';
import { User } from '../../../../models/user.model';
import { Espece, Race } from '../../../../models/animal.model';

export const adminActions = createActionGroup({
  source: 'admin',
  events: {
    'get all structure': emptyProps(),
    'get all structure success': props<{ structures: StructureVeterinaire[] }>(),
    'get all especes': emptyProps(),
    'get all especes success': props<{ especes: Espece[] }>(),
    'get all races': props<{ espece: string }>(),
    'get all races success': props<{ races: { [espece: string]: Race[] } }>(),
    'set active structure': props<{ activeStructure: StructureVeterinaire }>(),
    'reset active structure': emptyProps(),
    'get all communes': props<{ idStructure: string }>(),
    'get all commune success': props<{ communes: Commune[] }>(),
    //a voir pour avoir un endpoint produit/groupe/structure ?
    'get all produits': emptyProps(),
    'get all produits success': props<{ produits: Produit[] }>(),
    'save produit': props<{ produit: Produit }>(),
    'save produit success': props<{ produit: Produit }>(),
    'save acte': props<{ acte: Acte }>(),
    'save acte success': props<{ acte: Acte }>(),
    'get veto structure': emptyProps(),
    'get veto structure success': props<{ listVeto: Veterinaire[] }>(),
    'get asv list': emptyProps(),
    'get asv list success': props<{ listAsv: User[] }>(),
    'get selected structure': props<{ idStructure: string }>(),
    'get selected structure success': props<{ structure: StructureVeterinaire }>(),
    'get selected veto': props<{ idVeto: string | undefined }>(),
    'get selected veto success': props<{ selectedVeto: Veterinaire }>(),
    'get selected asv': props<{ idAsv: string }>(),
    'get selected asv success': props<{ selectedAsv: User }>(),
    'update veto': props<{ veto: Veterinaire }>(),
    'update veto success': props<{ veto: Veterinaire }>(),
    'update asv': props<{ asv: User }>(),
    'update asv success': props<{ asv: User }>(),
    'update structure': props<{ structure: StructureVeterinaire }>(),
    'update structure success': props<{ structure: StructureVeterinaire }>(),
  },
});
