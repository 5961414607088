import { Action, createReducer, on } from '@ngrx/store';

import { JwtToken } from 'app/models/jwt-token.model';
import { User } from 'app/models/user.model';
import { notifAction } from '../actions/notifications.action';
import { SessionActions } from '../actions/session.actions';
import { UserApiActions } from '../actions/user.actions';

export enum SessionLoading {
  login = 'LOGIN',
}

export interface SessionState {
  readonly isAppInitialized: boolean;
  readonly isUserAuthenticated: boolean;
  readonly updateAvailable: boolean;
  readonly structureUpdateAvailable: boolean;
  readonly especeUpdateAvailable: boolean;
  readonly raceUpdateAvailable: boolean;
  readonly user?: User;
  readonly credentials?: JwtToken;
  readonly deviceId?: string;
  readonly loading: Array<SessionLoading>;
  readonly loginError?: string;
  readonly receivedNotif?: boolean;
  readonly notification: any[];
}

export const initialState: SessionState = {
  isAppInitialized: false,
  isUserAuthenticated: false,
  updateAvailable: false,
  structureUpdateAvailable: false,
  especeUpdateAvailable: false,
  raceUpdateAvailable: false,
  loading: [],
  notification: [],
  receivedNotif: false,
};

export const sessionReducer = createReducer(
  initialState,
  on(SessionActions.finishAppInitialization, (state): SessionState => ({ ...state, isAppInitialized: true })),
  on(SessionActions.resetStateAndFinishAppInitialization, (): SessionState => ({ ...initialState, isAppInitialized: true })),
  on(notifAction.updateAvailable, (state): SessionState => ({ ...state, updateAvailable: true })),
  on(notifAction.structureUpdateAvailable, (state): SessionState => ({ ...state, structureUpdateAvailable: true })),
  on(notifAction.especeUpdateAvailable, (state): SessionState => ({ ...state, especeUpdateAvailable: true })),
  on(notifAction.raceUpdateAvailable, (state): SessionState => ({ ...state, raceUpdateAvailable: true })),
  on(UserApiActions.updateuserpreferencessuccess, (state, { preferences }): SessionState => ({ ...state, user: { ...state.user!, preferences } })),

  on(UserApiActions.login, (state): SessionState => ({ ...state, loading: [...state.loading, SessionLoading.login] })),
  on(
    UserApiActions.getUserAndDeviceIdSucceededAfterLogin,
    UserApiActions.loginError,
    (state): SessionState => ({
      ...state,
      loading: state.loading.filter(loading => loading !== SessionLoading.login),
    }),
  ),
  on(
    UserApiActions.loginSucceeded,
    SessionActions.setCredentials,
    (state, { credentials }): SessionState => ({ ...state, loading: [], credentials, loginError: undefined }),
  ),
  on(UserApiActions.loginError, (state, { error }): SessionState => ({ ...state, loginError: error, credentials: undefined })),

  on(
    UserApiActions.getUserAndDeviceIdSucceededAfterInit,
    UserApiActions.getUserAndDeviceIdSucceededAfterLogin,
    (state, { user, deviceId }): SessionState => ({
      ...state,
      user,
      deviceId,
      isUserAuthenticated: true,
    }),
  ),

  on(
    SessionActions.resetState,
    (): SessionState => ({
      ...initialState,
    }),
  ),

  //notifications
  on(
    notifAction.notifReceived,
    (state, { data, notification }): SessionState => ({
      ...state,
      receivedNotif: true,
      // eslint-disable-next-line no-unsafe-optional-chaining
      notification: [...state.notification, { data, notification }],
    }),
  ),
  on(notifAction.resetNotifState, (state): SessionState => ({ ...state, notification: [], receivedNotif: false })),
);

export function reducer(state: SessionState, action: Action): SessionState {
  return sessionReducer(state, action);
}
