import { Pipe, PipeTransform } from '@angular/core';
import { RdvState } from '../../../../models/rdv.model';

@Pipe({
  name: 'rdvStep',
  pure: true,
})
export class RdvStepPipe implements PipeTransform {
  constructor() {}

  transform(value: RdvState | string): string {
    switch (value) {
      case RdvState.ANNULEE:
        return 'Annulé (Vétophonie)';
      case RdvState.RDV_CLOTURE:
        return 'Clôturé';
      case RdvState.CONFIRM:
        return 'Confirmé (Vétophonie)';
      case RdvState.FROM_VETOPHONIE:
        return 'De vétophonie';
      case RdvState.INIT:
        return 'Initialisé';
      case RdvState.PAIEMENT_EN_COURS:
        return 'Paiement en cours';
      case RdvState.RDV_CONFIRME:
        return 'Confirmé';
      case RdvState.PAIEMENT_OK:
        return 'Paiement OK';
      case RdvState.RDV_A_CONFIRMER:
        return 'A confirmer';
      case RdvState.RDV_TERMINE:
        return 'Terminé';
      case RdvState.RDV_ANNULE:
        return 'Annulé';
      case RdvState.RDV_EN_COURS:
        return 'En cours';
      case RdvState.RDV_EN_ROUTE:
        return 'En route';
      case RdvState.REPLANIFICATION:
        return 'Replanification';
      case RdvState.TERMINEE:
        return 'Terminé (Vétophonie)';
      case RdvState.RDV_EN_ATTENTE:
        return 'En attente';
      case RdvState.RDV_VERIFIE:
        return 'Vérifié';
      case RdvState.ABANDONNEE:
        return 'Abandonnée';
      case RdvState.RDV_IMPAYE:
        return 'Impayé';
      default:
        return '-';
    }
  }
}
