import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { User, UserRole } from '../../../../models/user.model';
import { Editor, Toolbar } from 'ngx-editor';
import { AlertController, ModalController } from '@ionic/angular';
import { CompteRendu, EtatCompteRenduEnum, FileCR, FileCRTypeEnum } from '../../../../models/compte-rendu.model';
import { CompteRenduService } from '../../../rdv/wizard/services/compte-rendu.service';
import { firstValueFrom } from 'rxjs';
import { PdfViewerComponent } from '../pdf-viewer/pdf-viewer.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-standalone-cr',
  templateUrl: './standalone-cr.component.html',
  styleUrls: ['./standalone-cr.component.scss'],
})
export class StandaloneCrComponent implements OnInit {
  @Input() idAnimal!: string;
  @Input() nomAnimal?: string;
  @Input() user!: User;
  @Input() cr?: CompteRendu;

  editor: Editor;
  editorToolbar: Toolbar = [
    // default value
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
    ['horizontal_rule', 'format_clear'],
  ];
  dateComplement: Date = new Date();

  filesToUpload: FileCR[] = [];
  texte?: string;
  titre?: string;
  notifyClient = false;

  isSaving = false;
  now = new Date();

  constructor(
    private readonly crService: CompteRenduService,
    private readonly modalCtrl: ModalController,
    private readonly alertController: AlertController,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    this.editor = new Editor({
      history: true,
    });
  }

  ngOnInit() {
    if (this.cr) {
      this.texte = this.cr.textCr;
      this.titre = this.cr.titre;
      this.dateComplement = this.cr.dateCompteRendu!;
      this.filesToUpload = this.cr.files || [];
      if (
        UserRole.ROLE_VETERINAIRE === this.user.role &&
        this.cr.auteur?.role === UserRole.ROLE_BACKOFFICE &&
        this.cr.lastModifUser?.role !== UserRole.ROLE_VETERINAIRE
      ) {
        this.notifyClient = true;
      } else {
        this.notifyClient = false;
      }
    } else {
      this.notifyClient = this.user.role !== UserRole.ROLE_BACKOFFICE;
    }
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  async save() {
    this.isSaving = true;
    this.changeDetectorRef.markForCheck();
    if (this.cr) {
      const cr = {
        ...this.cr,
        textCr: this.texte !== '<p></p>' ? this.texte : undefined,
        titre: this.titre,
        dateCompteRendu: this.dateComplement,
        files: this.filesToUpload,
      };
      if (!cr.files) {
        cr.files = [];
      }
      for (let file of cr.files) {
        if (!file.file) {
          file.file = await firstValueFrom(this.crService.getFileBlob(file.id!))
            .then(blob => new Blob([blob]).arrayBuffer())
            .then(buffer => Array.from(new Uint8Array(buffer)));
        }
      }

      this.crService.uploadFiles(cr, this.idAnimal, this.notifyClient).subscribe(cr => {
        this.isSaving = false;
        this.modalCtrl.dismiss(cr);
      });
    } else {
      const cr: CompteRendu = {
        dateEnvoi: new Date(),
        nomAnimal: this.nomAnimal,
        idAnimal: this.idAnimal,
        etat: EtatCompteRenduEnum.ENVOYE,
        titre: this.titre,
        textCr: this.texte !== '<p></p>' ? this.texte : undefined,
        dateCompteRendu: this.dateComplement,
        files: this.filesToUpload,
      };
      this.crService.uploadFiles(cr, this.idAnimal, this.notifyClient).subscribe(cr => {
        this.isSaving = false;
        this.modalCtrl.dismiss(cr);
      });
    }
  }

  uploadFiles(files: FileCR[]) {
    this.filesToUpload.push(...files);
  }

  downloadFile(file: FileCR) {
    this.crService.downloadFile(file);
  }

  async viewFile(file: FileCR) {
    let blobProps: Blob | undefined;
    if (file.mimeType === 'application/pdf' || file.fileName?.endsWith('.pdf')) {
      blobProps = await firstValueFrom(this.crService.getFileBlob(file.id!)).then(blob => new Blob([blob]));
    } else {
      this.downloadFile(file);

      return;
    }
    const modal = await this.modalCtrl.create({
      component: PdfViewerComponent,
      cssClass: 'pdf-modal-size',
      componentProps: {
        typeProps: 'fichier',
        fileProps: file,
        blobProps,
      },
    });

    await modal.present();
  }

  deleteFile(index: number) {
    const fileCR = this.filesToUpload[index];
    if (fileCR.id !== undefined) {
      const alert = this.alertController.create({
        header: `Voulez-vous vraiment supprimer ce fichier ?`,
        buttons: [
          {
            role: 'cancel',
            text: `Non`,
            handler: () => {},
          },
          {
            role: 'confirm',
            text: `Oui`,
            handler: () =>
              this.crService
                .deleteFile(fileCR.id!)
                .pipe(take(1))
                .subscribe(() => {
                  this.filesToUpload = this.filesToUpload.filter((file: FileCR) => file.id !== fileCR.id);
                  this.changeDetectorRef.markForCheck();
                }),
          },
        ],
      });
      alert.then(a => a.present());
    } else {
      this.filesToUpload = this.filesToUpload.filter((file: FileCR) => file.id !== fileCR.id);
      this.changeDetectorRef.markForCheck();
    }
  }

  protected readonly FileCRTypeEnum = FileCRTypeEnum;
  protected readonly UserRole = UserRole;

  setDateComplement($event: any) {
    this.dateComplement = new Date($event);
  }
}
