import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromSession from '../reducers/session.reducer';

export const selectSessionState = createFeatureSelector<fromSession.SessionState>('session');

export const selectIsAppInitialized = createSelector(selectSessionState, (state: fromSession.SessionState) => state.isAppInitialized);
export const selectUpdateAvailable = createSelector(selectSessionState, (state: fromSession.SessionState) => state.updateAvailable);
export const selectStructureUpdateAvailable = createSelector(selectSessionState, (state: fromSession.SessionState) => state.structureUpdateAvailable);
export const selectEspeceUpdateAvailable = createSelector(selectSessionState, (state: fromSession.SessionState) => state.structureUpdateAvailable);
export const selectRaceUpdateAvailable = createSelector(selectSessionState, (state: fromSession.SessionState) => state.structureUpdateAvailable);

export const selectIsUserAuthenticated = createSelector(selectSessionState, (state: fromSession.SessionState) => state.isUserAuthenticated);
export const selectIsUserNotAuthenticated = createSelector(selectSessionState, (state: fromSession.SessionState) => !state.isUserAuthenticated);

export const selectCredentials = createSelector(selectSessionState, (state: fromSession.SessionState) => state.credentials);

export const selectLoading = (props: { loadingType: fromSession.SessionLoading }) =>
  createSelector(selectSessionState, (state: fromSession.SessionState) => state.loading.includes(props.loadingType));

export const selectUser = createSelector(selectSessionState, (state: fromSession.SessionState) => state.user);
export const selectLoginError = createSelector(selectSessionState, (state: fromSession.SessionState) => state.loginError);

export const selectDeviceTokenWithCredentials = createSelector(selectSessionState, (state: fromSession.SessionState) => ({
  deviceId: state.deviceId,
  credentials: state.credentials,
}));

export const selectUserWithDevice = createSelector(selectSessionState, (state: fromSession.SessionState) => ({
  deviceId: state.deviceId,
  userId: state.user?.id,
}));

export const selectReceivedPushNotification = createSelector(selectSessionState, (state: fromSession.SessionState) => ({
  notifReceived: state.receivedNotif,
  notifications: state.notification,
}));
