import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminService } from 'app/modules/admin/services/admin.service';
import { adminActions } from '../actions/admin.action';
import { map, of, switchMap } from 'rxjs';
import { VeterinaireService } from '../../../rdv/wizard/services/veterinaire.service';
import { StructureService } from '../../../rdv/wizard/services/structure.service';
import { tap } from 'rxjs/operators';
import { UserService } from '@core/services/user/user.service';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { StorageService } from '@core/services/storage/storage.service';
import { ReferenceService } from '../../../rdv/wizard/services/reference.service';
import { Race } from '../../../../models/animal.model';

@Injectable()
export class adminEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly adminService: AdminService,
    private readonly referenceService: ReferenceService,
    private readonly veterinaireService: VeterinaireService,
    private readonly storageService: StorageService,
    private readonly userService: UserService,
    private readonly structureService: StructureService,
    private readonly navCtrl: NavController,
    private readonly route: ActivatedRoute,
  ) {}

  getAllStructure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(adminActions.getAllStructure),
      switchMap(() => {
        if (this.storageService.getFromStorage('structures')) {
          return of(JSON.parse(this.storageService.getFromStorage('structures')!));
        } else {
          return this.adminService.getStructures().pipe(tap(structures => this.storageService.setToStorage('structures', JSON.stringify(structures))));
        }
      }),
      map(structures => adminActions.getAllStructureSuccess({ structures: structures })),
    );
  });

  getAllEspeces$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(adminActions.getAllEspeces),
      switchMap(() => {
        if (this.storageService.getFromStorage('especes')) {
          return of(JSON.parse(this.storageService.getFromStorage('especes')!));
        } else {
          return this.referenceService.getEspeces().pipe(tap(especes => this.storageService.setToStorage('especes', JSON.stringify(especes))));
        }
      }),
      map(especes => adminActions.getAllEspecesSuccess({ especes: especes })),
    );
  });

  getAllRaces$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(adminActions.getAllRaces),
      switchMap(action => {
        if (this.storageService.getFromStorage('races')) {
          const racesStorage: { [espece: string]: Race[] } = JSON.parse(this.storageService.getFromStorage('races')!);
          if (racesStorage[action.espece]) {
            return of(racesStorage);
          } else {
            return this.referenceService.getAnimalRaces(action.espece).pipe(
              tap(races => {
                racesStorage[action.espece] = races;
                this.storageService.setToStorage('races', JSON.stringify(racesStorage));
              }),
              map(() => racesStorage),
            );
          }
        } else {
          let racesToStore: { [key: string]: Race[] } = {};

          return this.referenceService.getAnimalRaces(action.espece).pipe(
            tap(races => {
              racesToStore[action.espece] = races;
              this.storageService.setToStorage('races', JSON.stringify(racesToStore));
            }),
            map(() => racesToStore),
          );
        }
      }),
      map(races => adminActions.getAllRacesSuccess({ races: races })),
    );
  });

  getAllCommune$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(adminActions.getAllCommunes),
      switchMap(action => this.adminService.getCommunes(action.idStructure!).pipe(map(communes => adminActions.getAllCommuneSuccess({ communes: communes })))),
    );
  });

  setActiveStructure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(adminActions.setActiveStructure),
        tap(action => {
          this.navCtrl.navigateForward([], {
            relativeTo: this.route,
            queryParams: {
              idStruct: action.activeStructure?.id,
            },
            queryParamsHandling: 'merge',
            skipLocationChange: false,
          });
        }),
      );
    },
    { dispatch: false },
  );

  getAllProduit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(adminActions.getAllProduits),
      switchMap(() => {
        return this.adminService.getProduits().pipe(
          map(produits => {
            return adminActions.getAllProduitsSuccess({ produits: produits });
          }),
        );
      }),
    );
  });

  updateProduit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(adminActions.saveProduit),
      switchMap(action => {
        return this.adminService.saveProduit(action.produit).pipe(
          map(produit => {
            return adminActions.saveProduitSuccess({ produit });
          }),
        );
      }),
    );
  });

  updateActe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(adminActions.saveActe),
      switchMap(action => {
        return this.adminService.saveActe(action.acte).pipe(
          map(acte => {
            return adminActions.saveActeSuccess({ acte: acte });
          }),
        );
      }),
    );
  });

  getVetoStructure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(adminActions.getVetoStructure),
      switchMap(() => this.veterinaireService.getVeto().pipe(map(data => adminActions.getVetoStructureSuccess({ listVeto: data })))),
    );
  });

  getAsvList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(adminActions.getAsvList),
      switchMap(() => this.userService.getAsvList().pipe(map(data => adminActions.getAsvListSuccess({ listAsv: data })))),
    );
  });

  getSelectedVeto$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(adminActions.getSelectedVeto),
      switchMap(action => {
        return this.veterinaireService.getVeterinaire(action.idVeto).pipe(
          map(veto => {
            return adminActions.getSelectedVetoSuccess({ selectedVeto: veto });
          }),
        );
      }),
    );
  });
  getSelectedVetoSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(adminActions.getSelectedVetoSuccess),
        tap(() => console.log('adminActions.getSelectedVetoSuccess')),
      );
    },
    {
      dispatch: false,
    },
  );

  getSelectedAsv$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(adminActions.getSelectedAsv),
      switchMap(action => {
        return this.userService.getAsv(action.idAsv).pipe(
          map(asv => {
            return adminActions.getSelectedAsvSuccess({ selectedAsv: asv });
          }),
        );
      }),
    );
  });
  getSelectedAsvSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(adminActions.getSelectedAsvSuccess),
        tap(() => console.log('adminActions.getSelectedAsvSuccess')),
      );
    },
    {
      dispatch: false,
    },
  );

  getSelectedStructure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(adminActions.getSelectedStructure),
      switchMap(action => {
        return this.adminService.getStructure(action.idStructure).pipe(
          map(structure => {
            return adminActions.getSelectedStructureSuccess({ structure });
          }),
        );
      }),
    );
  });

  updateVeto$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(adminActions.updateVeto),
      switchMap(action => {
        return this.veterinaireService.updateVeto(action.veto).pipe(
          map(veto => {
            return adminActions.updateVetoSuccess({ veto });
          }),
        );
      }),
    );
  });
  updateAsv$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(adminActions.updateAsv),
      switchMap(action => {
        return this.userService.updateAsv(action.asv).pipe(
          map(asv => {
            return adminActions.updateAsvSuccess({ asv });
          }),
        );
      }),
    );
  });

  updateVetoSuccess = createEffect(() => {
    return this.actions$.pipe(
      ofType(adminActions.updateVetoSuccess),
      map(() => {
        return adminActions.getVetoStructure();
      }),
    );
  });

  updateAsvSucess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(adminActions.updateAsvSuccess),
      map(() => {
        return adminActions.getAsvList();
      }),
    );
  });

  updateStructure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(adminActions.updateStructure),
      switchMap(action => {
        return this.structureService.updateStructure(action.structure).pipe(
          map(structure => {
            return adminActions.updateStructureSuccess({ structure });
          }),
        );
      }),
    );
  });
}
